import React from 'react';
import { Grid, Box } from '@mui/material';
import HeroSection2a from './HeroSection2a';
import DividerWithText from './DividerWithText';
import AdSenseAd from './Adsense';

const FeaturedGrid6 = () => {
  return (
    <Box sx={{ width: '85%', padding: '1rem', backgroundColor: 'white', margin: '0 auto' }}>
      <Grid container spacing={5} justifyContent="center">
        {/* Three across on large screens, full width on smaller screens */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <HeroSection2a
              featureText="FEATURED"
              headlineText="Our Fairytale Wedding Overlooking Sorrento, Italy"
              author="July 11, 2024"
              imagePath="FairytaleWedding/fairytalethumbnail.webp"
              linkUrl="/fairytalewedding"
              isFlipped={true}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <HeroSection2a
              featureText="TRAVEL"
              headlineText="The Best Souvenirs to Bring Home from Sorrento"
              author="August 30"
              imagePath="Homepage/SorrentoThumbnail.webp"
              linkUrl="/sorrento"
              isFlipped={true}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <HeroSection2a
              featureText="HOW-TO : IPHONE"
              headlineText="A Guide to Scroll-Stopping iPhone Photos"
              author="August 30"
              imagePath="Homepage/coverphoot.webp"
              linkUrl="/camera-settings"
              isFlipped={true}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturedGrid6;
