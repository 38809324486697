import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import HorizontalLine from '../Components/HorizontalLine';

const HeroSection2a = ({ featureText, headlineText, imagePath, linkUrl }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const heroLinkStyle = { textDecoration: 'none', color: '#000000', width: '100%' };
  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '450px',
  
    maxWidth: '100%',
    color: '#000000',
    backgroundColor: '#ffffff',
    borderRadius: '0px',
    overflow: 'hidden',
    border: 'none',
    boxSizing: 'border-box',
  };
  const imageContainerStyle = { width: 'auto', height: '100%', flex: 1, position: 'relative', overflow: 'hidden' };
  const imageStyle = { width: '100%', height: 'auto', objectFit: 'cover' };
  const contentStyle = {
    minHeight: '150px',
    paddingTop: '2rem',
    padding: '1rem ',
    textAlign: 'center',
    fontFamily: 'Playfair Display, serif',
  };
  const featureStyle = {
    fontSize: '20px',
    fontWeight: '200',
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#000000',
    fontFamily: 'Playfair Display, serif',
    fontWeight: '400',
    textDecoration: 'none',
    margin: '0 auto',
  };
  const headlineStyle = {
    fontSize: '1.5rem',
    textAlign: 'center',
    margin: '0 auto',
    lineHeight: 1.25,
    fontFamily: 'Playfair Display, serif',
    fontWeight: '400',
  };

  return (
    <Grid item xs={12} style={{ height: '100%' }}>
      <a href={linkUrl} style={heroLinkStyle}>
        <div style={cardStyle}>
          <div style={imageContainerStyle}>
            <img
              src={imagePath.startsWith('http') ? imagePath : `${process.env.PUBLIC_URL}/Images/Articles/${imagePath}`}
              alt={headlineText}
              style={imageStyle}
            />
          </div>
          <div style={contentStyle}>
            <h2 style={featureStyle}>{featureText}</h2>
            <HorizontalLine />
            <h1 style={headlineStyle}>{headlineText}</h1>
          </div>
        </div>
      </a>
    </Grid>
  );
};

export default HeroSection2a;