import React, { useState, useEffect } from "react";
import styles from "./HeroSectionA.module.css";

const HeroSectionA = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <a href="/articles/color-theory" className={styles.heroLinkA}>
      <section className={styles.heroSectionA}>

        <div className={styles.imageContainerA}>
          <img
            src="https://res.cloudinary.com/dvbubqhpp/image/upload/v1743276929/TrueSpring_use_for_thumbnail_reoexq.jpg"
            alt="Color Theory"
            className={styles.heroImageA}
          />
        </div>
        <div className={styles.textContainerA}>
          <h1 className={styles.titleA}>Color Theory</h1>
          <p className={styles.subtitleA}>

          12 Seasonal Color Types + The Best Lipstick for Your Color Season


          </p>
          <p className={styles.authorA}>BY JACKIE WYERS</p>
        </div>
      </section>
    </a>
  );
};

export default HeroSectionA;
