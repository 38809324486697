import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./ContrastQuiz.css";
import DividerWithText from "../Home/DividerWithText";
import SmallAdSenseAd from "../Home/AdsenseSmall";

const ContrastQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({
    skinTone: null,
    eyeColor: null,
    hairColor: null,
  });
  const [showResults, setShowResults] = useState(false);
  const [questionHeight, setQuestionHeight] = useState(null);
  const questionRef = useRef();

  const quizQuestions = [
    {
      question: "What is your skin tone?",
      options: [
        { text: "Fair to Light", value: "A", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735326625/LIGHT_SKIN_lgmndp.jpg" },
        { text: "Medium to Olive", value: "B", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735336060/Medium_Eyes-2_sau8qa.jpg" },
        { text: "Deep", value: "C", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735326624/DARK_SKIN_dcslfr.jpg" },
      ],
      key: "skinTone",
    },
    {
      question: "What is your eye color?",
      options: [
        { text: "Light", value: "A", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735326625/LightEyes_wcsa1u.jpg" },
        { text: "Medium", value: "B", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735333894/Medium_Eyes_nmc65t.jpg" },
        { text: "Dark", value: "C", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735326625/Dark_Eyes_qap39q.jpg" },
      ],
      key: "eyeColor",
    },
    {
      question: "What is your hair color?",
      options: [
        { text: "Light", value: "A", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735326625/LightHair_f8zycq.jpg" },
        { text: "Medium", value: "B", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735326625/MediumHair_wx2v50.jpg" },
        { text: "Dark", value: "C", backgroundImage: "https://res.cloudinary.com/dvbubqhpp/image/upload/f_webp/v1735326624/darkhair_dizygc.jpg" },
      ],
      key: "hairColor",
    },
  ];

  useEffect(() => {
    setQuestionHeight(questionRef.current?.offsetHeight || "auto");
  }, [currentQuestionIndex]);

  const handleAnswerClick = (questionKey, value) => {
    const updatedAnswers = { ...userAnswers, [questionKey]: value };
    setUserAnswers(updatedAnswers);

    if (Object.values(updatedAnswers).every((answer) => answer !== null)) {
      setShowResults(true);
      finishQuiz(updatedAnswers);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const finishQuiz = (answers) => {
    const result = calculateResult(answers);
    navigate(`/quiz/contrast-makeup-theory/${result}`);
  };

  const calculateResult = (answers) => {
    const { skinTone, eyeColor, hairColor } = answers;
    // Low Contrast
    if (skinTone === "A" && eyeColor === "B" && hairColor === "A") return "low-contrast"; // New Rule
    if (skinTone === "A" && eyeColor === "A" && hairColor === "A") return "low-contrast";
    if (skinTone === "B" && eyeColor === "B" && hairColor === "B") return "low-contrast";
    if (skinTone === "C" && eyeColor === "C" && hairColor === "C") return "low-contrast";
    if (skinTone === "C" && eyeColor === "B" && hairColor === "B") return "low-contrast";
    if (skinTone === "B" && eyeColor === "A" && hairColor === "A") return "low-contrast";
  
    // Medium Contrast
    if (skinTone === "A" && eyeColor === "B" && hairColor === "B") return "medium-contrast";
    if (skinTone === "B" && eyeColor === "B" && hairColor === "C") return "medium-contrast";
    if (skinTone === "A" && eyeColor === "A" && hairColor === "B") return "medium-contrast";
    if (skinTone === "B" && eyeColor === "A" && hairColor === "B") return "medium-contrast";
    if (skinTone === "A" && eyeColor === "C" && hairColor === "B") return "medium-contrast";
  
    // High Contrast
    if (skinTone === "A" && eyeColor === "A" && hairColor === "C") return "high-contrast";
    if (skinTone === "B" && eyeColor === "C" && hairColor === "C") return "high-contrast";
    if (skinTone === "C" && eyeColor === "A" && hairColor === "C") return "high-contrast";
    if (skinTone === "C" && eyeColor === "C" && hairColor === "A") return "high-contrast";
    if (skinTone === "B" && eyeColor === "A" && hairColor === "C") return "high-contrast";
    if (skinTone === "C" && eyeColor === "B" && hairColor === "C") return "high-contrast";
    if (skinTone === "B" && eyeColor === "C" && hairColor === "A") return "high-contrast"; 
  
    // New Case: Fair (A), Dark (C), Dark (C) -> High Contrast
    if (skinTone === "A" && eyeColor === "C" && hairColor === "C") return "high-contrast";
  
    // Ambiguous Combinations (Default to likely outcome based on the table)
    if (skinTone === "A" && eyeColor === "B" && hairColor === "C") return "high-contrast";
    if (skinTone === "B" && eyeColor === "C" && hairColor === "A") return "high-contrast";
    if (skinTone === "A" && eyeColor === "C" && hairColor === "B") return "medium-contrast";
    if (skinTone === "C" && eyeColor === "C" && hairColor === "B") return "low-contrast";
    if (skinTone === "B" && eyeColor === "A" && hairColor === "C") return "high-contrast";
  
    // Default if no exact match is found
    return "medium-contrast";
  };

  return (
    <div className="quiz2-container">
      <Helmet>
        <title>Contrast Level Quiz</title>
      </Helmet>
      {!showResults ? (
        <>
          <div className="quiz2-question" ref={questionRef} style={{ height: questionHeight }}>
            <div className="quiz2-question-text">{quizQuestions[currentQuestionIndex]?.question}</div>
          </div>
          <div className="quiz2-answer-section">
            {quizQuestions[currentQuestionIndex]?.options.map((option, index) => (
              <button
                key={index}
                className="quiz2-answer-button"
                style={{ backgroundImage: `url(${option.backgroundImage})` }}
                onClick={() => handleAnswerClick(quizQuestions[currentQuestionIndex].key, option.value)}
              >
                {option.text}
              </button>
            ))}
          </div>
        </>
      ) : (
        <div className="quiz2-results">
          <h2>Your Contrast Level</h2>
          <p>Your contrast level is: {calculateResult(userAnswers)}</p>
          <button onClick={() => navigate("/")}>Go Home</button>
        </div>
      )}
   
    </div>
  );
};

export default ContrastQuiz;
