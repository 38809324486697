import React from 'react';
import { Grid, Box } from '@mui/material';
import HeroSection2a from './HeroSection2a'; // Importing HeroSection2a format


const FeaturedGrid6 = () => {
  return (
    <Box sx={{ width: '85%', padding: '1rem', backgroundColor: 'white' , margin: '0 auto'}}>
     
     
      <Grid container spacing={1} justifyContent="center">
  
   

     


<Grid item xs={12} sm={6} md={6} lg={4}>
        <HeroSection2a
          featureText="POPULAR"
          headlineText="GALINDAFIED vs. BARBIECORE: Which Movie Marketing Campaign Reigns Supreme?"
          imagePath="Harley/rembeautywicked.jpg" // Path to the image for the article
          linkUrl="/articles/galindafied-vs-barbiecore" // URL where the article will be linked
        />
    
    </Grid>

        
<Grid item xs={12} sm={6} md={6} lg={4}>
  
<HeroSection2a
  featureText="FEATURED"
  headlineText="r.e.m. Beauty x WICKED Collection Review: Glinda Makeup Magic"
  imagePath="Wicked3/GlindaJackieWyers.jpg"
  linkUrl="/articles/rembeauty/wicked-review"
  isFlipped={true}
/>


</Grid>





      <Grid item xs={12} sm={6} md={6} lg={4}>
            <HeroSection2a
              featureText="TAYLOR : ERAS TOUR 2024"
              headlineText="Taylor Swift Holiday Merch 2024 - Ranked!"
              imagePath="TaylorSwift/JackieWyersTaylorSwiftBetty.webp"
              linkUrl="/articles/taylor-swift-holidays-2024"
              isFlipped={true}
            />
        
        </Grid>

      

     
      </Grid>
    </Box>
  );
};

export default FeaturedGrid6;